import { toRef } from 'vue'
import { useAPI, useCacheWrapper, useHydrationStore, useContextData, useNavigate, useSharedPromise } from '#imports'

import type { GetNavigationMenuResponseData } from '@winestyle/api-client/src/ts/api/catalog/v1/catalog_api_pb'
import type { SectionGroup } from '@/modules/nuxt-api/models/SectionGroup'

interface State {
  catalogCategories: SectionGroup[]
  sectionGroupsList: SectionGroup[]
  popularSections?: SectionGroup
}

export function useCategories () {
  const api = useAPI()
  const state = useHydrationStore<State>('categories-store', {
    catalogCategories: [],
    sectionGroupsList: [],
    popularSections: undefined
  }, { disableHydration: true })
  const navigationMenu = useContextData<GetNavigationMenuResponseData.AsObject | undefined>('navigation-menu')

  async function getNavigationMenu () {
    const { getNavigationMenu } = api.catalog()

    if (navigationMenu.value) {
      return navigationMenu.value
    }

    const data = await useSharedPromise('navigation-menu', () => useCacheWrapper('navigation-menu', async () => {
      return await getNavigationMenu()
    }))

    if (data) {
      navigationMenu.value = data
    }

    return navigationMenu.value
  }

  async function getSectionGroupsList () {
    if (state.value.sectionGroupsList.length === 0) {
      const data = await getNavigationMenu()

      state.value.sectionGroupsList = data?.sectionGroupsList || []
    }

    return state.value.sectionGroupsList
  }

  async function getPopularSections () {
    if (!state.value.popularSections) {
      const data = await getNavigationMenu()

      state.value.popularSections = data?.popularSections
    }

    return state.value.popularSections
  }

  async function getCategories () {
    const key = 'categories-list'
    const { getCategories } = api.catalog()

    return useSharedPromise(key, () => useCacheWrapper(key, async () => {
      const categories = (await getCategories()) ?? []

      categories.push(...[
        { code: 'champagnes-and-sparkling', title: '' },
        { code: 'novelty', title: '' },
        { code: 'bundles', title: '' },
        { code: 'spirit', title: '' },
        { code: 'gifts', title: '' },
        { code: 'other-drinks', title: '' },
        { code: 'cidre', title: '' },
        { code: 'gift_pack', title: '' },
        { code: 'rush_buy', title: '' },
        { code: 'kashasa', title: '' },
        { code: 'wine-sets', title: '' },
        { code: 'promo', title: '' }
      ])

      return categories.filter(v => v.code !== 'chairty')
    }))
  }

  async function getCatalogCategories () {
    if (state.value.catalogCategories?.length) { return state.value.catalogCategories }

    const [menu, popularSections] = await Promise.all([
      getSectionGroupsList(),
      getPopularSections()
    ])
    const res = menu.filter(el => el.displayType !== 3) ?? []

    if (popularSections) {
      res.unshift({ ...popularSections, sectionsList: popularSections.sectionsList?.slice(0, 9) ?? [] })
    }

    for (let i = 0; i < res.length; i++) {
      res[i].sectionsList = res[i].sectionsList.map(el => ({
        ...el,
        image: {
          ...el.image,
          url: getUrlWithTrailingSlashes(`${el.image?.url.replace('%w', '250').replace('%h', '250')}`, false)
        }
      }))
    }

    state.value.catalogCategories = res
  }

  async function getHeaderCategories () {
    const { getUrl } = useNavigate()
    const navigationMenu = await getNavigationMenu()

    const sectionsList = navigationMenu?.popularSections?.sectionsList || []

    return sectionsList.map((el) => {
      const item: { url: string, title: string, icon?: string, accent?: boolean } = {
        url: getUrl(el.path?.path || ''),
        title: el.title
      }

      if (el.icon) {
        item.icon = `i-${el.icon}`
      }

      if (el.path?.path.includes('promo')) {
        item.accent = true
      }

      return item
    })
  }

  // async function initializeMenu () {
  //   const navigationMenu = await getNavigationMenu()
  //
  //   if (navigationMenu) {
  //     state.value.catalogCategories = getCatalogCategories(navigationMenu)
  //   }
  // }

  return {
    getCategories,
    getNavigationMenu,
    getSectionGroupsList,
    getPopularSections,
    getHeaderCategories,
    getCatalogCategories,

    catalogCategories: toRef(state.value, 'catalogCategories')
  }
}
